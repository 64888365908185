import React from "react";
import { ConfigProvider } from "antd";

const AntdThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        footerBg: "#3C5B6F",
                    },
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default AntdThemeProvider;
