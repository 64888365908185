import React from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip } from "antd";
import "./fav-button.css";
import { Character } from "../pages/welcome";
import toast from "react-hot-toast";

interface AddToFavoritesButtonProps {
    character: Character;
}
const AddToFavoritesButton: React.FC<AddToFavoritesButtonProps> = ({ character }) => {
    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        const favorites = JSON.parse(localStorage.getItem("favorites") || "[]");
        const existing = favorites.some((favorite: Character) => favorite.id === character.id);

        if (existing) {
            toast.error(`${character.name} is already in favorites`);
        } else {
            favorites.push(character);
            localStorage.setItem("favorites", JSON.stringify(favorites));
            toast.success(`${character.name} added to favorites`);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Tooltip placement="top" title="Add to Favorites">
                <Button className="hover-icon-wrapper" size="small" onClick={handleClick}>
                    <Flex style={{ justifyContent: "center" }}>
                        <StarOutlined className="default-icon" style={{ fontSize: "20px" }} />
                        <StarFilled className="hovered-icon" style={{ fontSize: "20px" }} />
                    </Flex>
                </Button>
            </Tooltip>
        </div>
    );
};

export default AddToFavoritesButton;
