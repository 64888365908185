import { Flex, Layout } from "antd";
import React from "react";
import AntdThemeProvider from "./antd-theme-provider";
import { useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const AppLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();

    return (
        <AntdThemeProvider>
            <Layout style={{ minHeight: "100vh", height: "100%" }}>
                <Header>
                    <Flex
                        gap="large"
                        style={{
                            color: "white",
                            cursor: "pointer",
                        }}
                    >
                        <h1 onClick={() => navigate("/")} style={{ margin: 0, marginRight: "40px" }}>
                            Star Wars Characters
                        </h1>
                        <h3 onClick={() => navigate("/")} style={{ margin: 0 }}>
                            All Characters
                        </h3>

                        <h3 onClick={() => navigate("/favorites")} style={{ margin: 0 }}>
                            Favorites
                        </h3>
                    </Flex>
                </Header>
                <Content style={{ padding: "20px 48px 50px" }}>
                    <div
                        style={{
                            display: "flex",
                            background: "FFF2D7",
                            borderRadius: 2,
                            justifyContent: "center",
                        }}
                    >
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: "center", color: "white" }}>
                    ©{new Date().getFullYear()} Mykhailo Kazymyr
                </Footer>
            </Layout>
        </AntdThemeProvider>
    );
};

export default AppLayout;
