import React, { useEffect, useState } from "react";
import { Character } from "./welcome";
import AppLayout from "../components/app-layout";
import { Card, Col, Flex, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const FavoriteCharacters = () => {
    const [favorites, setFavorites] = useState<Character[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const storedFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
        setFavorites(storedFavorites);
    }, []);

    const handleRemoveFavorite = (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        characterId: string,
        characterName: string
    ) => {
        event.stopPropagation();
        const updatedFavorites = favorites.filter(fav => fav.id !== characterId);
        localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
        setFavorites(updatedFavorites);

        toast.success(`${characterName} successfully removed from favorites`);
    };

    return (
        <AppLayout>
            <Row gutter={[24, 24]} style={{ width: "100%" }}>
                {favorites.map((character, index) => (
                    <Col key={character.id + index} span={8}>
                        <Card
                            title={
                                <Flex style={{ justifyContent: "space-between" }}>
                                    <div>{character.name}</div>
                                    <DeleteOutlined
                                        style={{ fontSize: "20px" }}
                                        onClick={event => handleRemoveFavorite(event, character.id, character.name)}
                                    />
                                </Flex>
                            }
                            onClick={() => navigate("/character/" + character.id)}
                            style={{ minWidth: "200px", cursor: "pointer" }}
                        >
                            <h3>Films: </h3>
                            {character.films.map((film, index) => (
                                <p key={film.title + index}>{film.title}</p>
                            ))}
                        </Card>
                    </Col>
                ))}
            </Row>{" "}
        </AppLayout>
    );
};

export default FavoriteCharacters;
