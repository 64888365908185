/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nquery GetCharacter($personId: ID) {\n  person(id: $personId) {\n    id\n    name\n    birthYear\n    skinColor\n    eyeColor\n    gender\n    hairColor\n    height\n    mass\n    species {\n      id\n      name\n      language\n    }\n    homeworld {\n      id\n      name\n      orbitalPeriod\n      population\n      rotationPeriod\n      gravity\n    }\n    filmConnection {\n      films {\n        id\n        title\n        releaseDate\n        episodeID\n        director\n      }\n    }\n  }\n}\n": types.GetCharacterDocument,
    "\n  query Get10Characters($first: Int, $after: String) {\n      allPeople(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          cursor\n          node {\n            id\n            name\n          }\n        }\n      }\n  }\n": types.Get10CharactersDocument,
    "\n  query GetCharacters($first: Int, $after: String) {\n      allPeople(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            height\n            mass\n            homeworld {\n              id\n              name\n            }\n            species {\n              id\n              name\n            }\n            gender\n            eyeColor\n            filmConnection {\n              films {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n  }\n": types.GetCharactersDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetCharacter($personId: ID) {\n  person(id: $personId) {\n    id\n    name\n    birthYear\n    skinColor\n    eyeColor\n    gender\n    hairColor\n    height\n    mass\n    species {\n      id\n      name\n      language\n    }\n    homeworld {\n      id\n      name\n      orbitalPeriod\n      population\n      rotationPeriod\n      gravity\n    }\n    filmConnection {\n      films {\n        id\n        title\n        releaseDate\n        episodeID\n        director\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery GetCharacter($personId: ID) {\n  person(id: $personId) {\n    id\n    name\n    birthYear\n    skinColor\n    eyeColor\n    gender\n    hairColor\n    height\n    mass\n    species {\n      id\n      name\n      language\n    }\n    homeworld {\n      id\n      name\n      orbitalPeriod\n      population\n      rotationPeriod\n      gravity\n    }\n    filmConnection {\n      films {\n        id\n        title\n        releaseDate\n        episodeID\n        director\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Get10Characters($first: Int, $after: String) {\n      allPeople(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          cursor\n          node {\n            id\n            name\n          }\n        }\n      }\n  }\n"): (typeof documents)["\n  query Get10Characters($first: Int, $after: String) {\n      allPeople(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          cursor\n          node {\n            id\n            name\n          }\n        }\n      }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCharacters($first: Int, $after: String) {\n      allPeople(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            height\n            mass\n            homeworld {\n              id\n              name\n            }\n            species {\n              id\n              name\n            }\n            gender\n            eyeColor\n            filmConnection {\n              films {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n  }\n"): (typeof documents)["\n  query GetCharacters($first: Int, $after: String) {\n      allPeople(first: $first, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            height\n            mass\n            homeworld {\n              id\n              name\n            }\n            species {\n              id\n              name\n            }\n            gender\n            eyeColor\n            filmConnection {\n              films {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;