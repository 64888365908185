import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { Card, Flex } from "antd";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import Title from "antd/lib/typography/Title";
import { columns } from "../table-data/data";
import QueryResult from "../components/query-result";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const GET_CHARACTERS = gql(`
  query GetCharacters($first: Int, $after: String) {
      allPeople(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        edges {
          node {
            id
            name
            height
            mass
            homeworld {
              id
              name
            }
            species {
              id
              name
            }
            gender
            eyeColor
            filmConnection {
              films {
                id
                title
              }
            }
          }
        }
      }
  }
`);

export interface Character {
    id: string;
    name: string;
    height: number;
    weight: number;
    planet: string;
    species: string;
    gender: string;
    eyeColor: string;
    films: {
        title: string;
    }[];
}

const Welcome: React.FC = () => {
    const navigate = useNavigate();
    const { loading, error, data, fetchMore } = useQuery(GET_CHARACTERS, {
        variables: {
            first: 10,
        },
        // notifyOnNetworkStatusChange: true,
    });
    const [dataSource, setDataSource] = useState<Character[]>([]);

    const fetchMoreData = async (first = 10) => {
        if (data?.allPeople?.pageInfo?.hasNextPage) {
            fetchMore({
                variables: {
                    first: first,
                    after: data.allPeople.pageInfo.endCursor,
                },
            }).then();
        } else {
            toast.error("All rows were fetched!");
        }
    };

    useEffect(() => {
        if (data && data.allPeople && data.allPeople.edges) {
            const newData = data.allPeople.edges.map((edge: any) => ({
                id: edge.node.id,
                name: edge.node.name,
                height: edge.node.height || "-",
                weight: edge.node.mass || "-",
                planet: edge.node.homeworld ? edge.node.homeworld.name : "-",
                species: edge.node.species ? edge.node.species.name : "-",
                gender: edge.node.gender !== "n/a" ? edge.node.gender : "-",
                eyeColor: edge.node.eyeColor,
                films: edge.node.filmConnection.films || [],
            }));

            setDataSource(newData);
        }
    }, [data]);

    return (
        <QueryResult loading={loading} error={error} data={data}>
            <Flex vertical gap="large" style={{ width: "90%" }}>
                <Title style={{ textAlign: "center" }}>Welcome to coding solution from Mykhailo Kazymyr :)</Title>
                <Card title="Characters Table:" bordered={true}>
                    <Table
                        rowKey="id"
                        scroll={{ x: 1000 }}
                        dataSource={dataSource}
                        columns={columns}
                        onRow={(record, index) => {
                            return {
                                onClick: () => {
                                    navigate("/character/" + record.id);
                                },
                            };
                        }}
                        pagination={{
                            showSizeChanger: false,
                        }}
                        footer={() => (
                            <Flex style={{ justifyContent: "end" }}>
                                <Button
                                    onClick={() => fetchMoreData(10)}
                                    disabled={!data?.allPeople?.pageInfo.hasNextPage}
                                >
                                    Fetch More Rows!
                                </Button>
                            </Flex>
                        )}
                    />
                </Card>
            </Flex>
        </QueryResult>
    );
};

export default Welcome;
