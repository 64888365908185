import AddToFavoritesButton from "../components/add-to-favorites-button";
import { Character } from "../pages/welcome";

export const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 200,
    },
    {
        title: "Height",
        dataIndex: "height",
        key: "height",
        width: 100,
        sorter: (a: Character, b: Character) => a.height - b.height,
    },
    {
        title: "Weight",
        dataIndex: "weight",
        key: "weight",
        width: 100,
        sorter: (a: Character, b: Character) => a.weight - b.weight,
    },
    {
        title: "Planet",
        dataIndex: "planet",
        key: "planet",
        width: 140,
    },
    {
        title: "Species",
        dataIndex: "species",
        key: "species",
        width: 140,
        filters: [
            { text: "Human", value: "Human" },
            { text: "Droid", value: "Droid" },
            { text: "Wookie", value: "Wookie" },
            { text: "Rodian", value: "Rodian" },
            { text: "Hutt", value: "Hutt" },
            { text: "Yoda's species", value: "Yoda's species" },
            { text: "Trandoshan", value: "Trandoshan" },
            { text: "Mon Calamari", value: "Mon Calamari" },
            { text: "Ewok", value: "Ewok" },
            { text: "Sullustan", value: "Sullustan" },
            { text: "Neimodian", value: "Neimodian" },
            { text: "Gungan", value: "Gungan" },
            { text: "Toydarian", value: "Toydarian" },
            { text: "Dug", value: "Dug" },
            { text: "Twi'lek", value: "Twi'lek" },
            { text: "Aleena", value: "Aleena" },
            { text: "Vulptereen", value: "Vulptereen" },
            { text: "Xexto", value: "Xexto" },
            { text: "Toong", value: "Toong" },
            { text: "Cerean", value: "Cerean" },
            { text: "Nautolan", value: "Nautolan" },
            { text: "Zabrak", value: "Zabrak" },
            { text: "Tholothian", value: "Tholothian" },
            { text: "Iktotchi", value: "Iktotchi" },
            { text: "Quermian", value: "Quermian" },
            { text: "Kel Dor", value: "Kel Dor" },
            { text: "Chagrian", value: "Chagrian" },
            { text: "Geonosian", value: "Geonosian" },
            { text: "Mirialan", value: "Mirialan" },
            { text: "Clawdite", value: "Clawdite" },
            { text: "Besalisk", value: "Besalisk" },
            { text: "Kaminoan", value: "Kaminoan" },
            { text: "Skakoan", value: "Skakoan" },
            { text: "Muun", value: "Muun" },
            { text: "Togruta", value: "Togruta" },
            { text: "Kaleesh", value: "Kaleesh" },
            { text: "Pau'an", value: "Pau'an" },
        ],
        onFilter: (value: any, record: Character) => record.species === value,
    },
    {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        width: 120,
        filters: [
            {
                text: "Male",
                value: "male",
            },
            {
                text: "Female",
                value: "female",
            },
        ],
        onFilter: (value: any, record: any) => record.gender.indexOf(value as string) === 0,
    },
    {
        title: "Eye Color",
        dataIndex: "eyeColor",
        key: "eyeColor",
        width: 120,
        filters: [
            {
                text: "Blue",
                value: "blue",
            },
            {
                text: "Yellow",
                value: "yellow",
            },
            {
                text: "Red",
                value: "red",
            },
            {
                text: "Brown",
                value: "brown",
            },
            {
                text: "Orange",
                value: "orange",
            },
            {
                text: "Hazel",
                value: "hazel",
            },
            {
                text: "Black",
                value: "black",
            },
        ],
        onFilter: (value: any, record: Character) => record.eyeColor.includes(value as string),
    },
    {
        title: "Films",
        key: "films",
        width: 250,
        render: (record: Character) => {
            return record.films.map(film => film.title).join(", ");
        },
        filters: [
            {
                text: "A New Hope",
                value: "A New Hope",
            },
            {
                text: "The Empire Strikes Back",
                value: "The Empire Strikes Back",
            },
            {
                text: "Return of the Jedi",
                value: "Return of the Jedi",
            },
            {
                text: "The Phantom Menace",
                value: "The Phantom Menace",
            },
            {
                text: "Attack of the Clones",
                value: "Attack of the Clones",
            },
            {
                text: "Revenge of the Sith",
                value: "Revenge of the Sith",
            },
        ],
        onFilter: (value: any, record: Character) => record.films.some(film => film.title === value),
    },
    {
        title: "Action",
        key: "operation",
        render: (record: Character) => {
            return <AddToFavoritesButton character={record} />;
        },
        width: 60,
    },
];
