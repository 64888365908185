import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from "./pages/welcome";
import CharacterPage from "./pages/character-page";
import { Toaster } from "react-hot-toast";
import FavoriteCharacters from "./pages/favorite-characters";

function App() {
    return (
        <BrowserRouter>
            <Toaster position="bottom-right" />
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/favorites" element={<FavoriteCharacters />} />
                <Route path="/character/:characterId" element={<CharacterPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
