import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
    uri: "https://swapi-graphql.netlify.app/.netlify/functions/index",
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    allPeople: {
                        keyArgs: false,
                        merge(existing, incoming, { readField }) {
                            const mergedEdges = existing ? existing.edges.slice(0) : [];
                            if (incoming.edges) {
                                incoming.edges.forEach((edge: any) => {
                                    const idx = mergedEdges.findIndex(
                                        (e: any) => readField("id", e.node) === readField("id", edge.node)
                                    );
                                    if (idx === -1) {
                                        mergedEdges.push(edge);
                                    } else {
                                        mergedEdges[idx] = edge;
                                    }
                                });
                            }
                            return {
                                ...incoming,
                                edges: mergedEdges,
                            };
                        },
                    },
                },
            },
        },
    }),
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>
);

// reportWebVitals();
