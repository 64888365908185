import { ApolloError } from "@apollo/client";
import React from "react";
import AppLayout from "./app-layout";
import { Flex, Spin } from "antd";

interface QueryResultProps {
    loading: boolean;
    error?: ApolloError | undefined;
    data?: unknown;
}

const QueryResult: React.FC<React.PropsWithChildren<QueryResultProps>> = ({
    loading,
    error,
    data,
    children,
}): React.ReactElement<any, any> | null => {
    if (error) {
        return (
            <AppLayout>
                <p>ERROR: {error.message}</p>
            </AppLayout>
        );
    }

    if (loading) {
        return (
            <AppLayout>
                <Flex align="center">
                    <Spin size="large" />
                </Flex>
            </AppLayout>
        );
    }

    if (data) {
        return <AppLayout>{children}</AppLayout>;
    }

    return <p>Nothing to show...</p>;
};

export default QueryResult;
