import React from "react";
import { useParams } from "react-router-dom";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import QueryResult from "../components/query-result";
import { Card } from "antd";

const GET_CHARACTER = gql(`
query GetCharacter($personId: ID) {
  person(id: $personId) {
    id
    name
    birthYear
    skinColor
    eyeColor
    gender
    hairColor
    height
    mass
    species {
      id
      name
      language
    }
    homeworld {
      id
      name
      orbitalPeriod
      population
      rotationPeriod
      gravity
    }
    filmConnection {
      films {
        id
        title
        releaseDate
        episodeID
        director
      }
    }
  }
}
`);

const CharacterPage: React.FC = () => {
    const { characterId = "" } = useParams();
    const { loading, error, data } = useQuery(GET_CHARACTER, {
        variables: {
            personId: characterId,
        },
    });

    console.log(data);

    return (
        <QueryResult loading={loading} error={error} data={data}>
            <Card title={data?.person?.name} style={{ width: "100%" }}>
                <div style={{ fontWeight: "bold" }}>
                    Birth Year: <span style={{ fontWeight: "normal" }}>{data?.person?.birthYear}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Skin Color: <span style={{ fontWeight: "normal" }}>{data?.person?.skinColor}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Eye Color: <span style={{ fontWeight: "normal" }}>{data?.person?.eyeColor}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Gender: <span style={{ fontWeight: "normal" }}>{data?.person?.gender}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Hair Color: <span style={{ fontWeight: "normal" }}>{data?.person?.hairColor}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Height: <span style={{ fontWeight: "normal" }}>{data?.person?.height}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Weight: <span style={{ fontWeight: "normal" }}>{data?.person?.mass}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Species:
                    <div style={{ fontWeight: "normal", marginLeft: 20 }}>
                        <div>Name: {data?.person?.species?.name}</div>
                        <div>Language: {data?.person?.species?.language}</div>
                    </div>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Home World:
                    <div style={{ fontWeight: "normal", marginLeft: 20 }}>
                        <div>Name: {data?.person?.homeworld?.name}</div>
                        <div>Population: {data?.person?.homeworld?.population}</div>
                        <div>Orbital Period: {data?.person?.homeworld?.orbitalPeriod}</div>
                        <div>Rotation Period: {data?.person?.homeworld?.rotationPeriod}</div>
                        <div>Gravity: {data?.person?.homeworld?.gravity}</div>
                    </div>
                </div>
                <div style={{ fontWeight: "bold" }}>
                    Films:
                    {data?.person?.filmConnection?.films?.map((film, index) => (
                        <div key={film!.id + index}>
                            <div style={{ fontWeight: "bold", marginLeft: 20 }}>{film?.title}</div>
                            <div style={{ fontWeight: "normal", marginLeft: 40 }}>
                                <div>Release Date: {film?.releaseDate}</div>
                                <div>Episode Id: {film?.episodeID}</div>
                                <div>Director: {film?.director}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
        </QueryResult>
    );
};

export default CharacterPage;
